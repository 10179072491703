import React from 'react';
import { Typography, Container, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: '15vh',
  marginBottom: '5vh',
  padding: theme.spacing(3),
  textAlign: 'center'
}));

const CopyrightText = styled(Typography)({
  color: 'white',
  fontSize: '0.875rem'
});

function Copyright() {
  const currentYear = new Date().getFullYear();
  
  return (
    <CopyrightText>
      Designed and built by Edward Chen, Copyright © {currentYear}.
    </CopyrightText>
  );
}

function Footer() {
  return (
    <Container>
      <StyledFooter component="footer">
        <Copyright />
      </StyledFooter>
    </Container>
  );
}

export default Footer;
