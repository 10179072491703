import React from 'react';
import { Container, Fade, Grid2 as Grid, Typography } from "@mui/material";
import Typical from 'react-typical';

export default function Intro() {
  return (
    <div>
      <Container sx={{ marginTop: "35vh", marginBottom: "35vh" }}>
        <Grid container>
          <Grid item xs={8}>
            <Fade in={true} timeout={5000}>
              <Grid item xs={16}>
                <Typography
                  component="h1"
                  variant="h3"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  <Typical
                    steps={['Hello there! I am Edward,', 1000]}
                    loop={1}
                    wrapper="span"
                  />
                </Typography>
                <Typography
                  component="h1"
                  variant="h3"
                  align="left"
                  color="#FF9900"
                  gutterBottom
                >
                  <Typical
                    steps={['Cloud Architect @ Amazon Web Services', 1000]}
                    loop={1}
                    wrapper="span"
                  />
                </Typography>

                <Typography
                  component="h1"
                  variant="h5"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                />
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}