import React from 'react';
import { Container, Grid2 as Grid, Typography, CardMedia, Box } from "@mui/material";
import profilePic from '../../assets/profilePic.jpg';

const AboutMe = () => {
  return (
    <Container sx={{ marginTop: "10vh", marginBottom: "10vh" }}>
      <Grid container spacing={4}>
        <Grid item xs={8} md={4}>
          <Typography
            component="h2"
            variant="h4"
            align="left"
            color="#66FCF1"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            About Me
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            align="left"
            color="textPrimary"
            gutterBottom
            sx={{ marginBottom: '20px' }}
          >
            My journey into cloud computing began during an internship in 2020, where I had the chance to build web applications using React and AWS. That experience sparked a deep fascination with the power of cloud technology to create scalable and flexible solutions.
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            align="left"
            color="textPrimary"
            gutterBottom
            sx={{ marginBottom: '20px' }}
          >
            Beyond my professional pursuits, I am a passionate photographer who loves capturing the beauty of landscapes and cityscapes, constantly seeking new perspectives through my lens. I’m also a car enthusiast, immersing myself in everything from motorsports to car reviews—and, of course, the joy of driving itself.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CardMedia sx={{ width: "100%", maxWidth: "400px", borderRadius: '50%' }}>
              <img
                src={profilePic}
                width="100%"
                alt="Edward Chen - University of British Columbia - Amazon Web Services"
              />
            </CardMedia>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutMe;