import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Grid,
  ListItem,
  List,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tag } from "../Tag";
import {
  FiberManualRecord as BulletIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

const StyledCard = styled(Card)({
  width: "60vw",
  backgroundColor: "#1F2833",
  boxShadow: "none",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
});

const StyledBulletIcon = styled(BulletIcon)({
  fontSize: 10,
  color: "#66FCF1",
});

const StyledCompanyLogo = styled(CardMedia)({
  width: 90,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const WorkCard = ({
  company,
  jobTitle,
  date,
  companyLink,
  image,
  contents,
  tags,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (_, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Box sx={{ color: "#66FCF1", borderBottom: 3 }}>
      <StyledCard>
        <Grid container spacing={2}>
          {/* Company Logo Section */}
          <Grid
            item
            xs={12}
            lg={2}
            sx={{
              pt: 2, // Added padding top to align with text
            }}
          >
            <Button
              onClick={() => window.open(companyLink, "_blank")}
              aria-label={`Visit ${company} website`}
              sx={{ padding: 0 }} // Remove button padding
            >
              <StyledCompanyLogo
                component="img"
                image={image}
                alt={`${company} logo`}
              />
            </Button>
          </Grid>

          {/* Company Info Section */}
          <Grid item xs={12} lg={10}>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                align="left" // Explicit left alignment
                sx={{ mt: 2 }} // Match top padding of logo
              >
                {company}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                align="left"
              >
                {jobTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
              >
                {date}
              </Typography>
            </Box>

            {/* Accordion Section */}
            <StyledAccordion expanded={expanded} onChange={handleAccordionChange}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#66FCF1" }} />}
                aria-controls="work-content"
                id="work-header"
              >
                <Typography
                  variant="subtitle1"
                  align="left"
                >
                  {expanded ? "Hide Details" : "View Details"}
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails sx={{ padding: "8px 0" }}>
                <List disablePadding>
                  {contents.map((bullet, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        pl: 0,
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <StyledBulletIcon sx={{ mt: 1 }} />
                      <Typography
                        variant="body1"
                        sx={{ ml: 2 }}
                        align="left"
                      >
                        {bullet}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </StyledAccordion>
          </Grid>

          {/* Tags Section */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start", // Align tags to the left
              pl: 2 // Add some padding to align with content
            }}
          >
            <Box sx={{ mt: 2, mb: 2 }}>
              <Tag tags={tags} />
            </Box>
          </Grid>
        </Grid>
      </StyledCard>
    </Box>
  );
};

WorkCard.propTypes = {
  company: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  companyLink: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WorkCard;
