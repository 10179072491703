// src/pages/home/Certifications.js
import { Container, Grid2 as Grid, Typography } from "@mui/material";
import * as React from "react";
import BadgeCard from "../../components/cards/BadgeCard";
import { certifications } from "../../data/certifications";

export default function Certifications() {
  return (
    <div>
      <Container sx={{ marginTop: "10vh" }}>
        <Typography
          component="h2"
          variant="h4"
          align="left"
          color="#66FCF1"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Certifications
        </Typography>
        <Grid container spacing={3}>
          {certifications.map((cert, index) => (
            <Grid item xs={12} sm={6} md={2.4} key={index}>
              <BadgeCard
                link={cert.link}
                image={cert.image}
                title={cert.title}
                content={cert.content}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
